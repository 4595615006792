export const Bio = {
  name: "Sahil Hadkar",
  roles: [ "Robotics Engineer", "Instrumentation Engineer"],
  description:
    "I am a motivated and versatile individual, always eager to take on new challenges. With a passion for learning I am dedicated to delivering high-quality results. With a positive attitude and a growth mindset, I am ready to make a meaningful contribution and achieve great things.",
  // github: "https://github.com/SahilHadkar",
  resume:
    "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FSahilHadkar-CV-2024-M.pdf?alt=media&token=04fb131b-a717-4455-b1d5-aecea190b594",
  linkedin: "https://www.linkedin.com/in/sahilhadkar-77/",
};

export const skills = [
  {
    title: "Software & Tools",
    skills: [
      {
        name: "SolidWorks",
        image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2Fsolidworks.jpg?alt=media&token=18a7dd38-68a4-48ae-b589-2e872d9278bf",
      },
      {
        name: "AutoCAD",
        image: "https://upload.wikimedia.org/wikipedia/commons/6/6e/AutoCad_new_logo.svg",
      },
      {
        name: "CATIA",
        image: "https://upload.wikimedia.org/wikipedia/commons/d/dd/CATIA_Logotype_RGB_Blue.png",
      },
      {
        name: "MATLAB",
        image: "https://upload.wikimedia.org/wikipedia/commons/2/21/Matlab_Logo.png",
      },
      {
        name: "Simulink",
        image: "https://upload.wikimedia.org/wikipedia/commons/3/36/Simulink_Logo_%28non-wordmark%29.png"
      },
      {
        name: "Autodesk Fusion 360",
        image: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Fusion_360_Logo.png",
      },
      {
        name: "Robotic Operating System (ROS)",
        image: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Ros_logo.svg",
      },
      {
        name: "Gazebo Simulator",
        image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FGazebo.jpg?alt=media&token=635908bb-7583-4fdb-b6f0-af2a51e3e0b4",
      },
      {
        name: "Webots Simulator",
        image: "https://upload.wikimedia.org/wikipedia/commons/7/70/Simulation_of_a_Robotis_DARwIn-OP_in_Webots.png",
      },
      {
        name: "Ubuntu",
        image: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Ubuntu-logo-no-wordmark-solid-o-2022.svg",
      },
    ]
  },
  {
    title: "Languages",
    skills: [
      {
        name: "Python",
        image: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg",
      },
      {
        name: "C",
        image: "https://upload.wikimedia.org/wikipedia/commons/1/19/C_Logo.png",
      },
      {
        name: "C++",
        image: "https://upload.wikimedia.org/wikipedia/commons/1/18/ISO_C%2B%2B_Logo.svg",
      },
      {
        name: "JavaScript",
        image: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Javascript_badge.svg",
      },
      {
        name: "HTML",
        image: "https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg",
      },
      {
        name: "React JS",
        image: "https://upload.wikimedia.org/wikipedia/commons/4/47/React.svg",
      },
      {
        name: "node.js",
        image: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg",
      },
      {
        name: "CSS",
        image: "https://upload.wikimedia.org/wikipedia/commons/6/62/CSS3_logo.svg",
      },
      {
        name: "SQL",
        image: "https://upload.wikimedia.org/wikipedia/commons/4/44/SQL_%D0%BB%D0%BE%D0%B3%D0%BE%D1%82%D0%B8%D0%BF.png",
      },
      {
        name: "MySQL",
        image: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Database-mysql.svg",
      },
      {
        name: "Firebase",
        image: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Firebase_Logo_%28No_wordmark%29_%282024-%29.svg",
      },
    ]
  },
  {
    title: "Others",
    skills: [
      {
        name: "GitHub",
        image: "https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg",
      },
      {
        name: "Visual Studio Code",
        image: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Visual_Studio_Code_1.18_icon.svg",
      },
      {
        name: "Adobe Photoshop",
        image: "https://upload.wikimedia.org/wikipedia/commons/a/af/Adobe_Photoshop_CC_icon.svg",
      },
      {
        name: "Microsoft Office Suite",
        image: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Microsoft_Office_logo_%282013%E2%80%932019%29.svg",
      },
      {
        name: "Jupyter Notebook",
        image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FJupyter%20Notebook.png?alt=media&token=6c065033-4db7-443a-8958-f917332c6423",
      },
      {
        name: "LaTeX",
        image: "https://upload.wikimedia.org/wikipedia/commons/4/45/LaTeX_project_logo_bird.svg",
      },
      {
        name: "Siemens PLC",
        image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FSiemens.png?alt=media&token=d1a19c3c-5dd4-493b-b60e-c9e3aaf1c10b",
      },
      {
        name: "Figma",
        image: "https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg",
      },
      {
        name: "Google Colab",
        image: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Google_Colaboratory_SVG_Logo.svg",
      },
      {
        name: "LABView",
        image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FLabview.jpg?alt=media&token=e101e793-e160-423a-a30c-4200c2652ce3",
      },
    ],
  },
];

export const experiences = [
  {
    id:77,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FArihant%20Infosys.jpeg?alt=media&token=412a2b91-d7d8-4b1b-94e5-63c6026b77bc",
    role: "Key Account Manager",
    company: "Arihant Infosys, Mumbai, India",
    date: "October 2024 - Present",
    desc: " ",
    skills: ["Business to Business (B2B)", "Audio Visual (AV) Systems", "Designing", "Digital Electronics"],
  },
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FClouddata.jpeg?alt=media&token=c79335d0-bc62-4483-92af-5fbfa483ad84",
    role: "Robotics Software Engineer",
    company: "CloudData Technology LLC, Wilmington, USA",
    date: "Aug 2023 - July 2024 || 1 Year",
    desc: "During my time as a robotic software engineer at Cloudbig Technology LLC, I had the opportunity to work on several impactful projects that advanced the company's technological capabilities. I integrated A-Star and RRT algorithms for efficient path planning and navigation, which significantly enhanced the accuracy and speed of our robotic movements by 30%. Leading the development and implementation of ROS code for motion control, perception, and decision-making modules was particularly rewarding, as it resulted in a 30% improvement in robot functionality and a 20% reduction in development time. One of my most notable achievements was pioneering the integration of advanced machine learning algorithms to maximize the performance of our robotic software. This initiative led to a remarkable 15% increase in overall efficiency and productivity, revolutionizing time savings within the industry. Safety was always a top priority for me, so I implemented rigorous safety protocols and conducted thorough testing on Gazebo, which reduced the risk of accidents by 75%. Additionally, I worked closely with cross-functional teams to identify and resolve over 50 software bugs in the robot’s decision-making module. This collaboration led to a 40% reduction in system errors and significantly improved overall performance metrics. These experiences have equipped me with a deep understanding of robotic systems and a proven track record of driving technological advancements and enhancing efficiency and safety.",
    skills: [ "AStar Algorithm", "RRT", "ROS", "Gazebo Simulator", "ML Algorithms", "Decision-making", "System Errors solving", "Productivity", "Safety Protocols"],
  },
  {
    id: 11,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FuTest.png?alt=media&token=ffd02800-e62c-4d43-9def-8444db22fe6b",
    role: "Product Testing Trainee, New York, USA",
    company: "uTest",
    date: "July 2023 || 1 Month",
    desc: "As part of the Gauntlet Wearable Tech On-Site Project in NYC, I tested innovative digital wearable technology designed for arm and hand control. My responsibilities included providing detailed feedback on device functionality and user experience through a structured survey. I successfully completed the 3-hour testing commitment and was compensated with $150.",
    skills: [ "Testing", "Meta", "Artificial Intelligence", "Human Behavior" ],
  },
  {
    id: 10,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FKarmalize.png?alt=media&token=0ce92acb-5c85-4649-85c8-f7d707b1225e",
    role: "Production System Intern",
    company: "Karmalize.Me, New Jersey, USA",
    date: "May 2023 - July 2023 || 3 Months",
    desc: "During my internship as a production system intern at Karmalize.me, I played a key role in ensuring 95% equipment uptime by diligently monitoring production machinery and troubleshooting minor issues to maintain a smooth workflow in a fast-paced environment. I took pride in conducting over 50 quality inspections and product samplings, which ensured 100% compliance with food safety regulations and upheld our high standards for organic products. Additionally, I successfully enhanced inventory management accuracy by 15% through precise tracking of raw materials and finished products, along with meticulous record-keeping practices.",
    skills: [ "Production Management", "Product Sampling", "Organic Products", "Food Safety", "Microsoft Excel", "Packaging Machinery", "Food Packaging"],
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FReshamsingh.png?alt=media&token=b1139bd1-e753-440b-b811-a8175382a0c7",
    role: "Engineering Intern",
    company: "Reshamsingh Co PVT LTD, Mumbai, India",
    date: "Sept 2020 - Nov 2020 || 3 Months",
    desc: "In my role, I've implemented strategic solutions to optimize scheduling processes and ensure data accuracy. Through the development and execution of an EXCEL Solver-based scheduling system, I achieved a notable 20% reduction in daily report processing time while simultaneously enhancing accuracy by 15%. Additionally, my commitment to updating databases daily has significantly improved data integrity, guaranteeing that all information remains current and accurate. These initiatives reflect my dedication to streamlining operations and maintaining high standards of data quality within the organization",
    skills: [ "Advance Excel", "Excel", "Scheduling System"],
  },
  {
    id: 90,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FMCT.jpeg?alt=media&token=9d9fc913-43f4-41d6-9b0c-b221490b7b21",
    role: "Sports Secretary",
    company: "MCT Rajiv Gandhi Institute of Technology",
    date: "Aug 2019 - May 2020 || 10 Months",
    desc: "As Sports Secretary from August 2019 to May 2020, I was responsible for coordinating and managing over four sports events, including overseeing scheduling, logistics, and communication with participants to ensure smooth execution. I implemented and maintained detailed records of event participation, results, and communications, which significantly contributed to effective organization and reporting. My role required strong teamwork, leadership, and organizational skills, alongside a keen ability to handle financial analysis and event planning, all of which were essential for the successful execution of each event.",
    skills: [ "Teamwork", "Financial Analysis", "Leadership", "Organization Skills", "Excel", "Event Planning"]
  },
  {
    id: 88,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FMCT.jpeg?alt=media&token=9d9fc913-43f4-41d6-9b0c-b221490b7b21",
    role: "Digital Creative Secretary ",
    company: "MCT Rajiv Gandhi Institute of Technology",
    date: "Aug 2018 - Oct 2020 || 2 Years 3 Months",
    desc: "As the Digital Creative Secretary for ISA from August 2018 to October 2020, I spearheaded the development and management of over 50 digital content pieces, including social media posts and newsletters. My efforts led to a 30% increase in audience engagement and a 25% rise in online followership, significantly enhancing brand recognition. I was responsible for designing and producing visual assets for various digital campaigns, working closely with cross-functional teams to ensure cohesive messaging. My role also involved leading cross-functional teams, which contributed to a notable overall increase in engagement. My skills include expertise in Adobe Photoshop, content writing, and team management.",
    skills: [ "Adobe Photoshop", "Designing", "Team Management", "Leadership", "Content Writing"]
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FSiemens.png?alt=media&token=d1a19c3c-5dd4-493b-b60e-c9e3aaf1c10b",
    role: "Instrumentation Apprentice",
    company: "Siemens, Airoli, India",
    date: "Mar 2018 - May 2018 || 3 Months",
    desc: "I've demonstrated expertise in optimizing industrial processes through innovative integration solutions and automation techniques. By orchestrating seamless integration between PLCs and SCADA systems, I facilitated dynamic data visualization and data-driven decision-making, leading to a significant 40% reduction in production downtime and a remarkable 25% increase in overall operational efficiency. Leveraging Siemens HMI technologies, I revolutionized system monitoring capabilities, achieving real-time data visualization and an impressive 50% reduction in response time. Moreover, my proactive approach to identifying supply chain bottlenecks and implementing robotic process automation (RPA) solutions resulted in a notable 30% increase in production efficiency and a 25% reduction in cycle time, further contributing to enhanced productivity and streamlined operations within the organization.",
    skills: ["SCADA", "Siemens PLC", "HMI", "Profibus", "Ethernet"],
  },
];

export const education = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FStevens.png?alt=media&token=aae6b45e-c6bf-4fb8-8f94-ec12b6679b75",
    school: "Stevens Institute of Technology",
    date: "Sept 2021 - May 2023",
    grade: "3.5 GPA",
    desc: "My journey through a Master's degree in Robotics has been an enriching exploration of interdisciplinary realms, blending mechanical engineering, artificial intelligence, and computer programming. From laying the groundwork with Introduction to Robotics to delving into collaborative systems in Cooperating Autonomous Mobile Robots, each course has been pivotal in shaping my expertise. I've honed my programming skills through Engineering Programming: Python and leveraged Applied Machine Learning to develop intelligent algorithms. Courses like Optimization Principles and Numerical Methods in Mechanical Engineering fortified my analytical toolkit, crucial for tackling complex challenges in robotics design and control. Moreover, Autonomous Navigation for Mobile Robots and Wearable Robots and Sensors have broadened my understanding of emerging technologies and their applications. Through practical applications in Engineering Analysis - I, I've validated theoretical concepts, ensuring a robust foundation. Equipped with this multidisciplinary knowledge and practical experience, I'm poised to contribute meaningfully to the advancement of robotics technology, bridging theory and application in innovative ways.",
    degree: "Master of Engineering in Robotics",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FMCT.jpeg?alt=media&token=9d9fc913-43f4-41d6-9b0c-b221490b7b21",
    school: "MCT's Rajiv Gandhi Institute of Technology",
    date: "Aug 2016 - Oct 2020",
    grade: "7.11 CGPA",
    desc: "During my four-year bachelor's degree program in Instrumentation, I underwent comprehensive training in both theoretical concepts and practical applications essential to the field. My coursework encompassed a diverse range of subjects, starting with foundational courses such as Applied Mathematics, Applied Chemistry, and Applied Physics, which provided me with a strong scientific base. As I progressed, I delved into core engineering disciplines including Analog and Digital Electronics, Electrical Networks and Measurement, and Control System Design. Additionally, specialized courses such as Analytical Instrumentation, Signal Conditioning Circuit Design, and Biomedical Instrumentation equipped me with the specific skills needed for instrumentation in various industries. Furthermore, I gained hands-on experience in areas like Industrial Process Control, Image Processing, and Industrial Automation, preparing me for real-world challenges. Throughout the program, emphasis was placed on practical application through projects like Instrumentation Project Documentation and Execution, reinforcing my ability to translate theoretical knowledge into tangible solutions. Moreover, with courses like Internet of Things and Environmental Management, I honed my understanding of emerging technologies and their impact on the environment, ensuring a holistic approach to my education. Overall, my comprehensive coursework and practical experiences have provided me with a solid foundation to excel in the dynamic field of Instrumentation.",
    degree: "Bachelor of Engineering in Instrumentation",
  },
  {
    id: 88,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FNMFC.png?alt=media&token=236eb077-3bfe-43db-b62f-3d2e221544fb",
    school: "Nirmala Memorial Foundation College of Commerce & Science",
    date: "Aug 2015 - February 2016",
    grade: "63.69%",
    desc: "",
    degree: "HSC",
  },
  {
    id: 89,
    img: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Logos%2FSFIT.jpeg?alt=media&token=2e31efe6-2917-4c1d-ab8c-7a4d7da064eb",
    school: "St. Francis D'Assisi High School",
    date: "July 2013 - March 2014",
    grade: "82.80%",
    desc: "",
    degree: "SSC",
  },
];

export const projects = [

  // PROJECTS
  {
    id: 1,
    title: "Comparison of Approximation Methods using Predator-Prey Model",
    date: "November 2022",
    description: "The project delves into an examination of various numerical approximation techniques applied to the Predator-Prey model, emblematic of symbiotic relationships between two species, such as foxes and rabbits. This model, while originating in biological contexts, extends its utility to domains like behavioral economics. The study is grounded on foundational assumptions regarding population dynamics and environmental influences.The results unveil pivotal insights. Notably, Euler's method and Modified Euler's method exhibit a local truncation error of order 2, while the Runge-Kutta method of order 2 streamlines derivative computations. However, the Runge-Kutta-Fehlberg method emerges as the most promising contender, showcasing a local truncation error of O(h5). This performance superiority, aligned closely with the phase plots of the built-in ode45 function, underscores its heightened accuracy and efficiency in modeling predator-prey dynamics.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FNumerical.png?alt=media&token=6eb9af58-469c-4eff-a145-b61a5ca7091c",
    tags: ["Euler", "Modified-Euler", "Runge-Kutta-Fehlberg (RKF45)", "MATLAB"],
    category: "project",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FPredator-Prey.pdf?alt=media&token=1b29252a-0ba6-4e15-b098-ebee5b496957",
  },
  {
    id: 2,
    title: "Prediction of Unbanked Client's Repayment",
    date: "November 2022",
    description: "Utilized a dataset sourced from Kaggle to assess the efficacy of various machine learning algorithms, including Logistic Regression, Random Forest, Naive Bayes, Decision Trees, and XGBoost, in predicting repayment behavior among unbanked clients. By meticulously evaluating the performance of each algorithm in terms of accuracy, the project aimed to determine which method exhibited the highest predictive power. Through robust analysis and comparative assessment, the research aimed to provide valuable insights into the most effective algorithm for predicting repayment outcomes among unbanked individuals. This endeavor contributes to advancing predictive modeling techniques within the realm of financial inclusion, facilitating informed decision-making processes and enhancing risk management strategies in the banking sector.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FPUR.png?alt=media&token=b09cca4f-b732-4cb0-b661-4daa9f135efb",
    tags: ["Naives Bayes", "Logistic Regression", "Jupyter", "Python", "XGBoost", "Decision Tree", "Random Forest"],
    category: "project",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FPrediction.pdf?alt=media&token=4ab99226-eabe-43b0-9bed-9eef2c9a71fd",
  },
  {
    id: 3,
    title: "Optimization of Robotic Arm",
    date: "March 2022",
    description: "The Project aimed to minimize the overall weight of the robotic arm through the application of topology optimization techniques and subsequent redesign. By leveraging advanced methodologies, the project successfully achieved significant reductions in weight while maintaining structural integrity and functional effectiveness. This endeavor underscores the importance of innovative design approaches in enhancing the efficiency and performance of robotic systems, paving the way for more agile and cost-effective solutions in various applications.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FRA.png?alt=media&token=2fadee42-74e2-4461-a573-d0af2ce32b54",
    tags: ["SolidWorks", "CAD", "Modeling", "Designing", "Simulation", "Optimization Techniques"],
    category: "project",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FOptimization%20of%20Robotic%20Arm%20Final.pdf?alt=media&token=a83928d4-4b28-4b95-9ebd-8c65c02275a4",
  },
  {
    id: 4,
    title: "Robot Motion Planning in Dynamic Environment",
    date: "March 2022",
    description: "The Project is focused on the autonomous localization of a mobile robot within a dynamic environment using ROS simulator, implementing both the move_base package and the A* search algorithm. Through rigorous analysis, it was observed that while the move_base package exhibited limitations, the integration of the A* search algorithm significantly mitigated these drawbacks. By leveraging the existing A* algorithm and introducing tailored modifications, the project achieved notable enhancements in navigation efficiency and adaptability to dynamic surroundings. This research underscores the importance of algorithmic refinement in addressing real-world challenges, offering valuable insights into optimizing robotic motion planning for dynamic environments within the ROS framework.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FRMP.png?alt=media&token=5bf0d6eb-d7a8-40ad-a613-076bb5a9ebe9",
    tags: ["SLAM", "ROS", "Gazebo", "RRT", "AStar", "Path Planning"],
    category: "project",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FRobot%20Motion.pdf?alt=media&token=17b487d4-9d59-4a01-8e8d-4c42fa63cb79",
  },
  {
    id: 5,
    title: "Modeling of a Restaurant System",
    date: "November 2021",
    description: "Restaurants are ubiquitous establishments where patrons can enjoy a wide array of meals, spanning a variety of price ranges and culinary offerings. Within these establishments, a diverse workforce collaborates towards a common goal, encompassing roles such as waiters, chefs, managers, food delivery drivers, janitorial staff, and more. Understanding the efficiency of restaurant operations entails delving into the intricate flow of people through the establishment on any given day. By constructing simulation models, one can visualize and analyze the dynamics of customer movement within the restaurant, pinpointing areas for potential optimization. From the moment patrons step through the door to their eventual departure, each step of their journey can be scrutinized to identify bottlenecks or inefficiencies, ultimately leading to enhanced service and overall operational effectiveness.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FModel.png?alt=media&token=9bfce87f-640b-4c09-86be-724205d759cc",
    tags: ["Arena Software", "Modeling", "Simulation", "Food Service"],
    category: "project",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FModeling%20of%20Restaurant.pdf?alt=media&token=c9637c06-fa8d-481a-864b-c1c770984279",
  },
  {
    id: 6,
    title: "Reducing Output Error using Particle Swarm Optimization Technique",
    date: "March 2020",
    description: "The primary objective was to enhance transient response while minimizing execution time and iteration count through the integration of mutation operators—namely Cauchy, Levy, and Gaussian—within the Particle Swarm Optimization (PSO) algorithm. The project findings indicate notable enhancements in Integrated Squared Error (ISE), execution time, and iteration count, underscoring the efficacy of this approach in optimizing system performance. This research underscores the potential of leveraging innovative algorithmic combinations to achieve significant improvements in system efficiency and response dynamics.",
    image: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Starling_murmuration.jpg",
    tags: ["Transient Response", "MATLAB", "Cauchy-Levy-Gaussian Operators", "Mutation", "PSO", "PID Tuning"],
    category: "project",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Projects%2FPSO.pdf?alt=media&token=acfc0bcb-2c6a-43a4-97ff-93a1033ec8b5",
  },


  // PUBLICATION 

  {
    id: 7,
    title: "Comparison of Particle Swarm Optimization & Ziegler Nicholas Technique Vol. 06",
    date: "Fall 2019",
    description: "In this study, we conducted a comparative analysis between two tuning techniques: Particle Swarm Optimization (PSO) and Ziegler Nichols (ZN) method. PSO, a heuristic optimization approach, leverages swarm intelligence to iteratively enhance candidate solutions. Conversely, the ZN technique, specifically tailored for PID controller tuning, involves adjusting proportional gain until sustained oscillations are achieved while integral and derivative gains remain zero. We applied both methods to tune a PID controller regulating a second-order transfer function representing a DC motor. Through unit step input analysis, we evaluated system response in terms of settling time (Ts), peak overshoot (Mp), Integral Square Error (ISE), and Integral Absolute Error (IAE). This comparative assessment provides valuable insights into the performance of PSO and ZN techniques in optimizing controller parameters for dynamic systems.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Publication%2FIJREAM.jpg?alt=media&token=4ce2b152-7bb3-4e44-9013-5f0485ef539c",
    tags: ["Transient Response", "Ziegler-Nicholas", "PSO", "DC Motor", "PID Controller"],
    category: "publication",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Publication%2FPublication.pdf?alt=media&token=a394f4ee-25dd-4be1-8c42-962b29ccfb32",
  },


  // CERTIFICATION
  {
    id: 45,
    title: "Yealink Microsoft Teams Solution Technical",
    date: "20 October 2024",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FYealink%20Microsoft%20Teams%20Solution%20Technical.png?alt=media&token=cf54bb64-6029-453d-b82f-bc12e80e4924",
    tags: ["Yealink Products", "Microsoft Teams", "Technical Knowledge"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2F(YTST01014397)Yealink_Microsoft_Teams__Solution_Technical_Course.pdf?alt=media&token=f5c69dd2-b410-4c5a-9e33-1c90faf6190a",
  },
  {
    id:44,
    title: "Yealink Microsoft Teams Solution Sales",
    date: "15 October 2024",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FYealink%20Microsoft%20Teams%20Solution%20%20Sales.png?alt=media&token=df0ed419-247d-4b96-84f1-aec4cd5aba32",
    tags: ["Yealink Products", "Microsoft Teams", "Sales Stratergies"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2F(YTSS01014335)Yealink_Microsoft_Teams_Solution_Sales_Course.pdf?alt=media&token=510e2dd1-b8f8-4f3e-895c-d9f5ad199a6e",
  },
  {
    id: 40,
    title: "Learning Industrial Automation",
    date: "22 April 2024",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FLearning%20Autocad.png?alt=media&token=3a918e4c-e832-439f-9bea-e353c7bfd967",
    tags: ["Industrial Automation", "PLC Programming", "OPC Software", "SCADA"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FLearning%20Industrial%20Automation.pdf?alt=media&token=033c365f-8ec4-44da-9ca8-e7d4a8601962",
  },
  {
    id: 8,
    title: "Complete Java Mastery Course",
    date: "18 December 2023",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FComplete%20Java%20Mastery.png?alt=media&token=8f713dd6-420a-48cc-9942-953c3b8f0723",
    tags: ["Java", "OOPS", "DRY", "KISS", "SOLID", "Design Patterns"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FComplete%20Java%20Mastery.png?alt=media&token=8f713dd6-420a-48cc-9942-953c3b8f0723",
  },
  {
    id: 9,
    title: "Certified SOLIDWORKS Associate",
    date: "22 September 2023",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FCertified%20Solidworks%20Associate.png?alt=media&token=08355160-6844-4ccf-a56b-95d737c1f0e0",
    tags: ["SolidWorks", "Modeling", "Designing", "CAD"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FCertified%20Solidworks%20Associate.png?alt=media&token=08355160-6844-4ccf-a56b-95d737c1f0e0",
  },
  {
    id: 10,
    title: "Process Mining for Robotic Process Automation (RPA)",
    date: "28 July 2023",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FProcess%20Mining%20for%20Robotic%20Process%20Automation.png?alt=media&token=d050fb17-2b95-4da9-b878-044ef5fc9aa4",
    tags: ["RPA", "Process Mining"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FProcess%20Mining%20for%20Robotic%20Process%20Automation.png?alt=media&token=d050fb17-2b95-4da9-b878-044ef5fc9aa4",
  },
  {
    id: 11,
    title: "Introduction to Robotic Process Automation",
    date: "25 July 2023",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FIntroduction%20to%20Robotic%20Process%20Automation.png?alt=media&token=e0f483ab-93a1-44c5-8f26-57e5d8c366c6",
    tags: ["RPA"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FIntroduction%20to%20Robotic%20Process%20Automation.png?alt=media&token=e0f483ab-93a1-44c5-8f26-57e5d8c366c6",
  },
  {
    id: 12,
    title: "Elements of Artificial Intelligence (AI)",
    date: "24 July 2023",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FElements%20of%20AI.png?alt=media&token=62071903-08b2-4d19-bb07-d71abad6f2ea",
    tags: ["Artificial Intelligence"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FElements%20of%20AI.png?alt=media&token=62071903-08b2-4d19-bb07-d71abad6f2ea",
  },
  {
    id: 13,
    title: "MATLAB Master Class: Go From Beginner to Expert in MATLAB",
    date: "21 July 2023",
    image: "https://udemy-certificate.s3.amazonaws.com/image/UC-77130b28-3298-4224-885f-27474c3f635e.jpg",
    tags: ["MATLAB", "GUI", "Desktop App"],
    category: "certification",
    webapp: "https://udemy-certificate.s3.amazonaws.com/image/UC-77130b28-3298-4224-885f-27474c3f635e.jpg",
  },
  {
    id: 26,
    title: "MATLAB Programming & Sensor Integration Workshop",
    date: "10 July 2022",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FMATLAB%20Programming%20and%20Sensor%20Intergration.jpg?alt=media&token=8f6bf9e1-7e05-4ded-88e2-8a4ea8432bcf",
    tags: ["Sensors", "MATLAB Programming"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FMATLAB%20Programming%20and%20Sensor%20Intergration.jpg?alt=media&token=8f6bf9e1-7e05-4ded-88e2-8a4ea8432bcf",
  },
  {
    id: 14,
    title: "Learning Python (2020)",
    date: "04 March 2022",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FLearning%20Python.png?alt=media&token=56b5e774-fdf1-4dbe-aa3d-17bdde1216e7",
    tags: ["Python"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FLearning%20Python.png?alt=media&token=56b5e774-fdf1-4dbe-aa3d-17bdde1216e7",
  },
  {
    id: 15,
    title: "Programming Foundations: Fundamentals",
    date: "02 March 2022",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FProgramming%20Foundations.png?alt=media&token=2f5276ca-9cbb-42f8-a651-b398ee4a7e3a",
    tags: ["Python", "OOP"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FProgramming%20Foundations.png?alt=media&token=2f5276ca-9cbb-42f8-a651-b398ee4a7e3a",
  },
  {
    id: 16,
    title: "Fusion 360 Essential Training",
    date: "01 March 2022",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FFusion%20360.png?alt=media&token=a21ad4dd-6109-4ebd-932a-9d49d46aaa5c",
    tags: ["CAD", "Designing", "Modeling"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FFusion%20360.png?alt=media&token=a21ad4dd-6109-4ebd-932a-9d49d46aaa5c",
  },
  {
    id: 17,
    title: "Learning AutoCAD",
    date: "10 February 2022",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FLearning%20Autocad.png?alt=media&token=3a918e4c-e832-439f-9bea-e353c7bfd967",
    tags: ["CAD", "Designing"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FLearning%20Autocad.png?alt=media&token=3a918e4c-e832-439f-9bea-e353c7bfd967",
  },
  {
    id: 18,
    title: "Industrial Automation & Robotics Program",
    date: "24 June 2019",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FIndustrial%20Automation%20%26%20Robotics%20Program.png?alt=media&token=ad1409f9-1cfe-4177-832a-33749f034d19",
    tags: ["Automation", "Robotics", "Programming"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FIndustrial%20Automation%20%26%20Robotics%20Program.png?alt=media&token=ad1409f9-1cfe-4177-832a-33749f034d19",
  },
  {
    id: 19,
    title: "Internet of Things",
    date: "15 June 2018",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FIOT.jpg?alt=media&token=18b606d7-8679-43f5-92e5-e8224c67f35f",
    tags: ["Raspberry Pi 3", "Cloud", "IOT", "C++"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FIOT.jpg?alt=media&token=18b606d7-8679-43f5-92e5-e8224c67f35f",
  },
  {
    id: 20,
    title: "Maharashtra State Certificate in Information Technology",
    date: "16 November 2016",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FMSCIT.jpg?alt=media&token=f15b1449-4d00-4a14-b730-48f7c76623d7",
    tags: ["Information Systems", "MS Office", "Advance EXCEL"],
    category: "certification",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FMSCIT.jpg?alt=media&token=f15b1449-4d00-4a14-b730-48f7c76623d7",
  },

  // VIRTUAL EXPERIENCE
  {
    id: 29,
    title: "Operations Industrial Engineer",
    date: "24 June 2024",
    description: "In June 2024, I completed the Siemens Mobility Operations Industrial Engineer Job Simulation on Forage. This involved a comprehensive project focused on optimizing operations and redesigning layouts for Siemens Mobility's high-speed rail team. I employed manufacturing and engineering principles to identify inefficiencies within the production process, particularly targeting the wheel assembly section. Through data-driven decision-making and process analysis, I pinpointed a critical bottleneck and proposed actionable improvements that enhanced workflow and reduced waste. To support my recommendations, I developed a detailed PowerPoint presentation, showcasing my technical communication skills and strategic planning to justify the proposed layout changes, ultimately improving operational efficiency.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FOperations%20Industrial%20Engineer.png?alt=media&token=22e1bc37-ff64-4195-aa94-a8da2164eb1b",
    tags: ["Data Analysis", "Lean Manufacturing", "Process Optimization", "Statistical Process Control", "System Thinking"],
    category: "virtual experience",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FOperations%20Industrial%20Engineer.pdf?alt=media&token=c60bfa3e-4bb9-4208-a282-26966a589fd9",
  },
  {
    id: 28,
    title: "Product Designer",
    date: "13 June 2024",
    description: "I recently completed the Accenture North America Product Design Virtual Experience Program on Forage in June 2024. During this simulation, I focused on transforming user experiences within a product design team. I designed and implemented a new feature, iterating on an existing product screen to enhance user interaction and satisfaction. Additionally, I effectively communicated my design decisions, providing clear explanations of the rationale behind my feature choices and improvements.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FAccenture%20-%20Product%20Design.png?alt=media&token=564c8298-da6e-4f12-a467-1224c4c63afa",
    tags: ["Figma", "Product Design", "Rationale", "UI Design", "UX Design"],
    category: "virtual experience",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FAccenture%20-%20Product%20Design.pdf?alt=media&token=256991c1-385f-45bf-a1e0-327c1ed6e989",

  },
  {
    id: 27,
    title: "Production Expert",
    date: "12 June 2024",
    description: "In June 2024, I completed the Diageo Manufacturing Job Simulation through Forage, where I gained valuable insights into the bourbon production process. During this simulation, I conducted a thorough review of the entire production process for the supply chain and manufacturing team at Diageo. I analyzed deviations from standard process parameters, identified their underlying causes, and evaluated downtime data. Leveraging this information, I developed a strategic plan to improve operational efficiencies, demonstrating a strong commitment to a continuous improvement mindset. This experience honed my analytical skills and reinforced my ability to implement effective process improvements in a manufacturing setting.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FDiageo%20-%20Manufacturing.png?alt=media&token=38d9b713-cbb8-4fcb-96fc-c5ed6f538c55",
    tags: ["Critical Thinking", "Data Analysis", "Problem-Solving", "Process Optimization", "Research", "Technical Knowledge"],
    category: "virtual experience",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Certifications%2FDiageo%20-%20Manufacturing%20Job%20Simulation.pdf?alt=media&token=123768d5-3108-4939-91a0-893463f9f84a",
  },
  {
    id: 21,
    title: "EV Intern",
    date: "11 April 2024",
    description: "Through the Ford EV Engineering job simulation on Forage in April 2024, I gained valuable insights into cutting-edge battery technology and control systems crucial for electric vehicle (EV) development. Utilizing Excel, I analyzed data to elucidate the intricate connection between battery chemistries and their capacities, shedding light on optimizing EV performance. Moreover, dissecting Python code for tuning a Proportional-Integral-Derivative (PID) controller provided a deeper understanding of its pivotal role in enhancing control accuracy within EV systems. This immersive experience not only broadened my knowledge but also honed practical skills essential for thriving in the dynamic field of automotive engineering.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FFord%20EV%20Intro.png?alt=media&token=2b76e068-499f-4540-81c5-a7967149ac6a",
    tags: ["Critical Thinking", "Data Analysis", "Excel", "PID Control"],
    category: "virtual experience",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FFord%20EV%20Intro.png?alt=media&token=2b76e068-499f-4540-81c5-a7967149ac6a",
  },
  {
    id: 22,
    title: "EV Intern",
    date: "11 April 2024",
    description: "During my participation in the Ford EV Engineering job simulation, I engaged in a thorough analysis of the cost-benefit dynamics surrounding EV batteries and LiDAR sensors. I scrutinized diverse battery form factors, such as cylindrical, pouch, and prismatic options, evaluating their impact on overall system cost, safety, and longevity. Furthermore, I conducted an in-depth assessment of various LiDAR sensor models, including mechanical scanning, solid-state, and hybrid variants, emphasizing factors like durability, scanning speed, and cost implications. Ultimately, I synthesized these technical findings into concise presentations, furnishing the engineering division with actionable insights to facilitate informed decision-making across multiple projects.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FFord%20EV%20Intermediate.png?alt=media&token=447964ba-908e-490a-b1f0-8ea3ea5ef49e",
    tags: ["Cost-Benefit Analysis", "Critical Thinking", "Design", "Sensor"],
    category: "virtual experience",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FFord%20EV%20Intermediate.png?alt=media&token=447964ba-908e-490a-b1f0-8ea3ea5ef49e",
  },
  {
    id: 23,
    title: "EV Intern",
    date: "11 April 2024",
    description: "Through my participation in the Ford EV Engineering Job Simulation on Forage in April 2024, I delved into the intricacies of battery testing protocols for electric vehicles (EVs), proposing enhancements to streamline efficiency. I analyzed battery performance across a spectrum of scenarios, including passenger vehicles, electric school buses, and long-haul trucks, providing valuable insights into optimizing battery functionality for diverse applications. Additionally, I honed my skills in robotics by constructing a robot operating system (ROS) package, mastering fundamental concepts and intricacies of ROS components. This experience allowed me to seamlessly integrate ROS components, configuring publishers and subscribers to facilitate smooth communication among robotics systems, showcasing my adaptability and practical expertise in this domain.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FFord%20EV%20Advanced.png?alt=media&token=96c10d7a-a974-46f2-8096-3ff3c3092e58",
    tags: ["Python", "Robotics", "ROS Development", "Software Architecture", "Testing Protocols", "Data Analysis"],
    category: "virtual experience",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FFord%20EV%20Advanced.png?alt=media&token=96c10d7a-a974-46f2-8096-3ff3c3092e58",
  },
  {
    id: 24,
    title: "Data Science Intern",
    date: "14 March 2024",
    description: "I recently completed a job simulation for Cognizant's Data Science team, where I delved into the world of AI with a focus on Gala Groceries, one of their technology-driven clients. Using Python and Google Colab, I conducted exploratory data analysis to uncover insights and patterns within the data. Additionally, I developed a Python module containing code to train a model and generate performance metrics, essential for the Machine Learning engineering team. Finally, I distilled my findings and analysis into a concise PowerPoint presentation to effectively communicate the results back to the business stakeholders. This experience reinforced my ability to leverage AI tools and techniques to drive actionable insights and support informed decision-making within a business context.",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FAI.png?alt=media&token=88348a4f-7bef-4ba9-be04-901b26f5ae39",
    tags: ["Data Analysis", "Data Modeling", "Data Visualization", "Machine Learning", "Python", "Model Interpretation"],
    category: "virtual experience",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FAI.png?alt=media&token=88348a4f-7bef-4ba9-be04-901b26f5ae39",
  },
  {
    id: 25,
    title: "Automation Developer",
    date: "09 March 2024",
    description:"In March 2024, I successfully undertook a job simulation centered on advising ABC Staffing, a hypothetical client, in automating their timesheet-to-invoice process as an Automation Developer within the Datacom Automation Job Simulation on Forage platform. Through meticulous analysis, I pinpointed the most suitable process automation solution tailored to the client's specific requirements. Utilizing my expertise, I meticulously crafted a process flow tailored to a key persona involved in the automation design, ensuring seamless integration and optimal efficiency. This experience honed my skills in process optimization and automation, equipping me with invaluable insights into streamlining workflows and enhancing productivity within organizational settings",
    image: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FDatacom.png?alt=media&token=bce893ba-3e4a-4237-9c47-59559d985500",
    tags: ["Critical Thinking", "Problem Solving", "Visualisation", "Process Improvement" ],
    category: "virtual experience",
    //github: "",
    webapp: "https://firebasestorage.googleapis.com/v0/b/sahilhadkar-07.appspot.com/o/Virtual%20Experience%2FDatacom.png?alt=media&token=bce893ba-3e4a-4237-9c47-59559d985500",
  },

];

export const TimeLineData = [
  //{ year: 2016, text: "Started my journey" },
  //{ year: 2018, text: "Worked as a freelance developer" },
  //{ year: 2019, text: "Founded JavaScript Mastery" },
  //{ year: 2020, text: "Shared my projects with the world" },
  //{ year: 2021, text: "Started my own platform" },
];
